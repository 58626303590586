import React, {useState} from 'react';
import {Document, Page} from 'react-pdf';
import PC from '../assets/Programme-COULON.pdf';

export default function Voyage() {
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({numPages}) {
    setNumPages(numPages);
  }

  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      alignContent: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      alignSelf: 'center',
    }}>
      <Document
        file={PC}
        options={{workerSrc: "pdf.worker.js"}}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={console.error}
        flexWrap
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1}/>
        ))}
      </Document>
    </div>
  )
}