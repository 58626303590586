import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import {useStyles} from "../style/styles";

export function Title(props) {
  const classes = useStyles();
  let color = "primary";
  if (props.color) {
    color = "secondary"
  }
  return (
    <Typography  className={classes.mainText} component="h1" variant="h2" color={color} gutterBottom>
      {props.children}
    </Typography>
  );
}

export function SubTitle(props) {
  const classes = useStyles();
  let color = "primary";
  if (props.color) {
    color = "secondary"
  }
  return (
    <Typography className={classes.mainText} component="h2" variant="h4" color={color} gutterBottom>
      {props.children}
    </Typography>
  );
}

export function ItalicTitle(props) {
  const classes = useStyles();
  let color = "primary";
  if (props.color) {
    color = "secondary"
  }
  return (
    <Typography className={classes.mainText} component="h3" variant="h6" color={color} gutterBottom>
      <i>{props.children}</i>
    </Typography>
  );
}


Title.propTypes = {
  children: PropTypes.node,
};

SubTitle.propTypes = {
  children: PropTypes.node,
};