import Contact from "../secondary/Contact";
import CopyrightComponent from "../secondary/CopyrightComponent";
import React from "react";
import {newStyle} from "../style/styles";

export default function Footer() {
  const classes = newStyle();
  return  (<div className={classes.footer}>
    <div className={classes.footerItem}>
      <CopyrightComponent/>
    </div>
  </div>);
}