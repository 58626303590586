import React, {useState} from 'react';
import {Document, Page} from 'react-pdf';
import PC from '../assets/Programme-COULON.pdf';

export default function BamboozlePrivacy() {
  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      alignContent: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      alignSelf: 'center',
    }}>
      <h1>Bamboozle App Privacy Information</h1>
      <h2>English</h2>
      <h3>Privacy Bamboozle App</h3>
      <p>No data or information collected from the users "players".</p>
      <h2>French / Français</h2>
      <h3>Politique de confidentialité de Bamboozle App</h3>
      <p>Aucune donnée n'est collecté par Bamboozle app sur ses utilisateurs "joueurs".</p>
    </div>
  )
}