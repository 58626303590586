import React from "react";
import {newStyle} from "../style/styles";

export default function TagLine() {
  const classes = newStyle();

  return (
    <div className={classes.tagLineDiv}>
      <h2 className={classes.tagLineH}>Redefine startup/pocket ideas to full scale products.</h2>
      <h3 className={classes.tagLineHSub}>An R&D first company!</h3>
    </div>
  );
}