import React from 'react';
import BisouImg from '../assets/119_test.jpg';
import {SubTitle, Title, ItalicTitle} from "../secondary/TitleComponent";

export default function Bisou() {
  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      alignContent: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      alignSelf: 'center',
    }}>
      <Title>{'Bisoux bisoux bisoux !'}</Title>
      <SubTitle>{'Merci pour tout !'}</SubTitle>
      <img src={BisouImg} alt={'Bisoux'}/>
      <ItalicTitle>{'De votre serviteur Marc ❤'}</ItalicTitle>
    </div>
  )
}