import React from "react";
import {newStyle} from "../style/styles";

export default function TechBlog() {
  const classes = newStyle();

  return (
    <div className={classes.simpleDiv}>
      <h4 className={classes.sub}>TechBlog</h4>
      <p>Coming very soon !</p>
    </div>
  );
}