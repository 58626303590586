import Link from "@material-ui/core/Link";
import React from "react";
import {useStyles} from "../style/styles";

export default function Copyright() {
  const classes = useStyles();
  return (
    <div className={classes.contact}>
      <p className={classes.secondaryText}>Made with love on Vim & JetBrains</p>
        <p className={classes.secondaryText}>
        {'Copyright © SidoHorizon '}
        {new Date().getFullYear()}
      </p>
    </div>
  );
}
