import React from "react";
import {newStyle} from "../style/styles";

export default function Wait() {
  const classes = newStyle();
  return (
    <div className={classes.waitContainer}>
      <div style={{aligItems: 'center', justifyContent: 'center',textAlign: 'center'}}>
        <h1 className={classes.titleWhite}>Sarces</h1>
        <h2 className={classes.titleWhite}>Lusty, weird, extra group of people!</h2>
      </div>
    </div>
  );
}
