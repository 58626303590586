import React from "react";
import {newStyle} from "../style/styles";

export default function Nav() {
  const classes = newStyle();

  return (
    <nav className={classes.nav}>
      <h2 className={classes.title}>SidoHorizon</h2>
      <div className={classes.navLeft}>
        <h4 className={classes.subNav}>{'What we offer ?'}</h4>
        <h4 className={classes.subNav}>{'Projects'}</h4>
        <h4 className={classes.subNav}>{'TechBlog'}</h4>
        <h4 className={classes.subNav}>{'Team'}</h4>
        <h4 className={classes.subNav}>{'Contact'}</h4>
      </div>
    </nav>
  );
}
