import React from "react";
import {newStyle, useStyles} from "../style/styles";
import Footer from "./Footer";
import Projects from "./Projects";
import Nav from "./Nav";
import TagLine from "./TagLine";
import Quote from "./Quote";
import TechBlog from "./TechBlog";

export default function AppContainer() {
  const classes = newStyle();
  return (
    <div className={classes.main}>
      <Nav />
      <TagLine />
      <Projects />
      <Quote />
      <TechBlog />
      <Footer />
    </div>
  );
}