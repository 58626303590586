import React from "react";
import {newStyle} from "../style/styles";
import {Button} from "@material-ui/core";

export default function Quote() {
  const classes = newStyle();

  return (
    <div className={classes.quoteDiv}>
      <h4 className={classes.whiteBig}>Want to work together ? Any request ?</h4>
      <Button variant="contained"><a style={{textDecoration: 'none', color: '#1664cb', fontWeight: 'bold'}} href={"mailto:marc@sidohorizon.fr"}>Contact Us</a></Button>
    </div>
  );
}