import React from "react";
import {newStyle, useStyles} from "../style/styles";

export default function Projects() {
  const classes = newStyle();

  return (
    <div className={classes.projectsDiv}>
      <div className={classes.block}>
        <h3 className={classes.subNav}>
          Projects & Products
        </h3>
      </div>
      <div className={classes.projectsList}>
      <div className={classes.projectBlock}>
        <h3 className={classes.projectName}>GiftBook</h3>
        <p className={classes.p}>Wishlist powerful API</p>
      </div>
      <div className={classes.projectBlock}>
        <h3 className={classes.projectName}>MelanomaScanApp</h3>
        <p className={classes.p}>Skin Cancer diagnosis tool</p>
      </div>
      <div className={classes.projectBlock}>
        <h3 className={classes.projectName}>Bamboozle</h3>
        <p className={classes.p}>Custom free french drink and party android application</p>
      </div>
      </div>
    </div>
  );
}
